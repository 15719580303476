<template>
  <div>
    <div class="sproject-content">
      <div class="container">
        <Alert :variant="variant" v-if="text">{{ text }}</Alert>
        <div v-if="isEditor && project.role !== 'hidden'" class="btns-group--right">
          <span v-if="countNewMembers"
            class="message_badge"
            :class="getClassBadgeLength(countNewMembers)"
            title="Unread comments"
          >{{countNewMembers}}</span>
          <router-link
            :to="{
              name: 'strategyProjectsEditor',
              params: { id: project.slug },
            }"
            class="btn-edit"
            exact
            :style="styleButton"
          >
            Edit Strategy Project
          </router-link>
        </div>
        <div v-else-if="project.role !== 'hidden'" class="btns-group--right">
          <a v-if="group && group.role === 'guest'"
            class="btn-edit"
            @click.prevent="onRequestMembership(group.role !== 'request')"
            :style="styleButton"
          >
            Join the group
          </a>
          <a v-else-if="group && group.role === 'request'"
            class="btn-edit"
            @click.prevent="onRequestMembership(group.role !== 'request')"
            :style="styleButton"
          >
            Undo Group Request
          </a>
          <a v-else
            class="btn-edit"
            @click.prevent="onRequestMembership(project.role !== 'request')"
            :style="styleButton"
          >
            {{project.role === 'request' ? 'Undo Request' : 'Request Membership'}}
          </a>
        </div>
        <StrategyProjectsArticle
          :project="projectData"
        />
      </div>
    </div>
    <div class="discussion__head">
      <div class="container">
        <div class="discussion__head-wrapper">
          <span style="position: relative;">
            <span v-if="unreadFeedsCount"
              class="message_badge"
              :class="getClassBadgeLength(unreadFeedsCount)"
            >{{unreadFeedsCount}}</span>
            Discussion
          </span>
        </div>
      </div>
    </div>
    <PostsContainer
      style="max-width: 1080px; width: 100%; margin: 0 auto;"
      :channel-id="channelId"
      :posts="posts"
      :headers="headers"
      :showBtnAddPost="canAddPosts"
      :authUserRole="project.role"
      :isFilter="true"
      funcCreatePost="createPostProject"
      :unreadFeeds="unreadFeeds"
      @showFilter="$emit('showFilter')"
      @getPosts="getPosts"
      @deletePost="onDeletePost"
      @changePostParams="onChangePostParams"
    />
    <Modal
      @close="hideModal(modalUndoRequest)"
      :id=modalUndoRequest
      title=""
      buttonTextOk="Undo"
      buttonVariantOk="danger"
      :showButtonCancel="true"
      @cancel="hideModal(modalUndoRequest)"
      @ok="onUndoRequest"
    >
      <p>Are you sure?</p>
    </Modal>
    <Modal
      @close="hideModal(modalSendRequest)"
      :id=modalSendRequest
      title=""
      buttonTextOk="Yes"
      buttonVariantOk="danger"
      :showButtonCancel="true"
      @cancel="hideModal(modalSendRequest)"
      @ok="sendRequestProject"
    >
      <p>Are you sure?</p>
    </Modal>
    <Modal
      @close="hideModal(modalSendGroupRequest)"
      :id=modalSendGroupRequest
      title=""
      buttonTextOk="Yes"
      buttonVariantOk="danger"
      :showButtonCancel="true"
      @cancel="hideModal(modalSendGroupRequest)"
      @ok="sendRequestGroup"
    >
      <p>Are you sure?</p>
    </Modal>
  </div>
</template>
<script>
import ProjectService from '@/services/ProjectService';
import GroupService from '@/services/GroupService';

import exception from '@/mixins/exception';
import alert from '@/mixins/alert';
import me from '@/mixins/me';
import style from '@/mixins/style';
import modal from '@/mixins/modal';
import websocket from '@/mixins/websocket';
import badge from '@/mixins/badge';

import StrategyProjectsArticle from '@/views/pages/StrategyProjectView/Components/StrategyProjectsArticle.vue';
import Alert from '@/components/Alert.vue';
import Modal from '@/components/Modal.vue';

import PostsContainer from '@/components/PostsContainer.vue';

import postProjectMixin from '@/mixins/posts/postProjectMixin';

export default {
  name: 'StrategyProjectActive',
  components: {
    PostsContainer,
    StrategyProjectsArticle,
    Alert,
    Modal,
  },
  mixins: [exception, alert, me, modal, websocket, badge, style, postProjectMixin],
  props: {
    project: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    textAlert: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      projectData: {},
      comments: [],
      deletePostId: undefined,
      postsShow: 0,
      page: 0,
      totalPosts: 0,
      tempAlert: this.textAlert,
      modalDeletePost: 'modalDeletePost',
      modalUndoRequest: 'modalUndoRequest',
      modalSendRequest: 'modalSendRequest',
      modalSendGroupRequest: 'modalSendGroupRequest',
      styleButton: {
        backgroundColor: this.project.style_color,
        color: this.getContrastYIQ(this.project.style_color),
      },
      unreadFeedsCount: 0,
      channelId: '',
    };
  },
  computed: {
    users() {
      if (!(this.project.author || this.project.users)) {
        return [];
      }
      return [this.project.author, ...this.project.users];
    },
    isEditor() {
      return this.project.role !== 'request' && this.project.role !== 'guest';
    },
    isCanSeeComments() {
      if (this.group?.type === 'Private'
        && (this.group.role === 'guest' || this.group.role === 'request' || this.group.role === 'hidden')
      ) return false;

      if (this.project.type === 'Private'
        && (this.project.role === 'guest' || this.project.role === 'request' || this.project.role === 'hidden')
      ) return false;

      // if (this.group?.role === 'guest' && this.project.role === 'guest') return false;
      // if (this.group?.role === 'guest' && this.project.role === 'request') return false;
      // if (this.group?.role === 'request' && this.project.role === 'guest') return false;
      // if (this.group?.role === 'request' && this.project.role === 'request') return false;
      if (this.project.role === 'hidden') return false;

      return true;
    },
    countNewMembers() {
      return this.$store.getters.getUnreadMembersCount;
    },
    canAddPosts() {
      return this.project.role === 'member'
        || this.project.role === 'admin'
        || this.project.role === 'owner';
    },
    canShowPosts() {
      return this.posts && this.canAddPosts;
    },
    unreadFeeds() {
      return this.$store.getters.getUnreadPosts || [];
    },
  },
  async beforeRouteUpdate(to, from, next) {
    this.loadEvents();

    next();
  },
  async created() {
    this.channelId = this.project.channel;
    this.$eventBus.$on('loadedGroup', this.getPosts);
    this.$eventBus.$on('addedFeed', this.addFeed);

    this.loadEvents();

    if (
      (
        this.project.type === 'Private'
        && (this.project.role === 'guest'
          || this.project.role === 'request'
          || this.project.role === 'hidden'
        )
      )
      || (
        this.group
        && this.group.role === 'guest'
        && this.project.role === 'guest'
      )
      || (
        this.group
        && this.group.role === 'guest'
        && this.project.role === 'request'
      )
      || (
        this.group
        && this.group.role === 'request'
        && this.project.role === 'guest'
      )
      || (
        this.group
        && this.group.role === 'request'
        && this.project.role === 'request'
      )
    ) {
      this.projectData = {
        id: this.project.id,
        category_id: this.project.category_id,
        created_at: this.project.created_at,
        name: this.project.name,
        text: this.project.text_short,
        type: this.project.type,
        summary: this.project.summary,
        summary_file: this.project.summary_file,
        video_html: this.project.video_html,
        style_color: this.project.style_color,
        style_name: this.project.style_name,
      };

      if (this.project.role === 'hidden') {
        this.setAlert({ textArg: 'On this project you are locked', variantArg: 'danger' });
      }
      return;
    }

    if (this.project.role === 'hidden') {
      this.setAlert({ textArg: 'On this project you are locked', variantArg: 'danger' });
    }

    this.subscribeToChannel('project', this.setUnreadPostsAndCommentsFromSocket, this.project.id);
    await this.getProjectData();
  },
  methods: {
    loadEvents() {
      this.$eventBus.$on('showAlert', this.showAlert);
    },
    async getProjectData() {
      let res = null;

      try {
        res = await ProjectService.getData(this.project.id);

        res.data.id = this.project.id;
        res.data.video_html = this.project.video_html;
        res.data.style_color = this.project.style_color;
        res.data.style_name = this.project.style_name;
        this.projectData = res.data;
      } catch (e) {
        this.projectData = {};
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
        this.handleException(e);
      }
    },
    showAlert(message, type = 'success') {
      this.setAlert({ textArg: message, variantArg: type });
    },
    async onRequestMembership(status) {
      if (status) {
        if (this.group?.role === 'guest') {
          if (this.project.type === 'Open') {
            this.showModal(this.modalSendGroupRequest);
          } else {
            this.$eventBus.$emit('projectLocked');
          }
        } else if (this.project.type === 'Open') {
          this.showModal(this.modalSendRequest);
        } else {
          const res = await this.sendRequestProject();
          this.hideModal(this.modalSendRequest);

          if (res.data.status === 200) {
            this.$eventBus.$emit('updateUserRole', 'request');
            this.showAndHideAlertSuccess(res.data.message, 'success');
          } else {
            this.showAndHideAlertSuccess(res.response.data.message, 'danger');
          }
        }

        return;
      }

      this.showModal(this.modalUndoRequest);
    },
    async sendRequestProject() {
      try {
        const res = await ProjectService.actionUser(this.project.id, { action: 'request' });

        this.hideModal(this.modalSendRequest);

        if (res.data.status === 200) {
          this.$eventBus.$emit('updateUserRole', 'member');
          this.showAndHideAlertSuccess(res.data.message, 'success');
        } else {
          this.showAndHideAlertSuccess(res.response.data.message, 'danger');
        }

        return res;
      } catch (e) {
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
        console.log({ textArg: e, variantArg: 'danger' });
        return e;
      }
    },
    async sendRequestGroup() {
      try {
        const res = await GroupService.actionUser(this.group.id, { action: 'request' });

        if (res.data.status === 200) {
          this.$eventBus.$emit('updateUserGroupRole', 'member');
          this.hideModal(this.modalSendGroupRequest);
          this.setAlert({ textArg: res.data.message, variantArg: 'success' });
        }
      } catch (e) {
        console.log({ textArg: e, variantArg: 'danger' });
      }
    },
    onUndoRequest() {
      if (this.group?.type === 'Private') this.undoRequestGroup();
      else this.undoRequestProject();
    },
    async undoRequestGroup() {
      try {
        const res = await GroupService.actionUser(this.group.id, { action: 'undo' });

        if (res.data.status === 200) {
          const alertText = {
            text: res.data.message,
            variant: 'success',
          };

          this.$router.push({ name: 'strategyProjectsPage', params: { alertText } });
        }
      } catch (e) {
        console.log({ textArg: e, variantArg: 'danger' });
      }
    },
    async undoRequestProject() {
      try {
        const res = await ProjectService.actionUser(this.$route.params.id, { action: 'undo', user_id: this.me.id });

        if (res.data.status === 200) {
          this.hideModal(this.modalUndoRequest);
          this.setAlert({ textArg: res.data.message, variantArg: 'success' });
          this.$eventBus.$emit('sendRequestProjectSuccess', res.data.message);
          this.$eventBus.$emit('updateUserRole', 'guest');
        }
      } catch (e) {
        console.log({ textArg: e, variantArg: 'danger' });
      }
    },
    setUnreadPostsAndCommentsFromSocket(event) {
      const unreadPostsAndComments = JSON.parse(event.data).message;
      const newFeed = JSON.parse(event.data).message_update;

      if (!this
        .$store
        .getters
        .getUnreadPosts.length) {
        this.$store.commit(
          'SET_UNREAD_POSTS',
          [...unreadPostsAndComments.feed],
        );

        this.$store.commit(
          'SET_UNREAD_POSTS_COUNT',
          this.$store.getters.getUnreadPosts.length,
        );

        this.$store.commit(
          'SET_UNREAD_MEMBERS_COUNT',
          unreadPostsAndComments.members.length,
        );
      }

      if (newFeed) {
        if (!newFeed.parent_id) this.$eventBus.$emit('updatedPost', newFeed);
        else this.$eventBus.$emit('updatedAnswerComment', newFeed);
      }
    },
  },
  watch: {
    tempAlert: {
      immediate: true,
      handler(text) {
        this.setAlert({ textArg: this.tempAlert || text, variantArg: 'success' });
      },
    },
  },
  beforeDestroy() {
    this.$store.commit('SET_UNREAD_POSTS_COUNT', 0);
    this.$store.commit('SET_UNREAD_POSTS', []);

    this.$eventBus.$off('showAlert', this.showAlert);
  },
};
</script>
<style  lang="scss" scoped>
  .btns-group--right {
    align-items: center;
  }

  .sproject-content {
    background-color: #FFFFFF;
  }

  .discussion__head {
    background-color: #FFFFFF;
    margin-bottom: 0;
    padding-bottom: 30px;
  }

  .messages {
    background-color: #FFFFFF;
  }

  .btn-edit {
    cursor: pointer;
  }
  .message_badge {
    &.two {
      width: 30px;
      border-radius: 40%;
    }

    &.three {
      width: 40px;
      border-radius: 40%;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;

    // position: absolute;
    // top: -10px;
    // right: -10px;

    background-color: red;
    border-radius: 50%;

    z-index: 1;

    color: #fff;
    font-size: 12px;
    font-weight: 600;

    margin-right: 10px;

    // @media (max-width: 992px) {
    //   right: -20px;
    // }
  }
</style>
