<template>
  <div class="project__slider-item">
    <div class="project__slider-box">
      <div class="project__slider-head">
        <div class="project__slider-avatar">
          <img v-if="project.author && project.author.avatar" :src="project.author.avatar" alt="">
          <img v-else src="@/assets/images/avatar.svg" alt="">
        </div>
        <div class="project__slider-author">
          <h5>{{project.author.name}}</h5>
          <ul class="sproject-article__category">
            <li><a style="cursor: default">{{project.category_id}}</a></li>
            <li><a style="cursor: default">{{project.type}}</a></li>
          </ul>
        </div>
      </div>
      <div class="project__slider-title">{{project.name}}</div>
      <div class="project__slider-date">{{project.created_at}}</div>
      <div class="project__slider-text">{{project.text_short}}</div>
      <div class="project__slider-action">
        <router-link
          :to="{ name: 'strategyProjectPageView', params: { id: project.slug } }"
          class="btn-view"
        >
          View Strategy Project
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardRelatedStrategyProjects',
  components: {},
  mixins: [],
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
