<template>
  <div>
    <div class="sproject-content">
      <div class="container">
        <Alert :variant="variant" v-if="text">{{ text }}</Alert>
        <div class="sproject-article">
          <div class="sproject-article__date">
            <span>{{dayOfMonth}}</span>{{dayOfWeek}}
          </div>
          <h3 class="sproject-article__title">
            {{project.name}}
          </h3>

        <ul class="sproject-article__category">
          <li><a style="cursor: default">{{project.category_id}}</a></li>
          <li><a style="cursor: default">{{project.type}}</a></li>
        </ul>

        <ul v-if="false" class="social-list">
          <!-- <li class="social-list__item">
            <a href="">
              <img class="icon-flag" src="@/assets/images/flag-icon.svg" alt="">
              </a>
          </li> -->
          <li class="social-list__item">
            <a href="https://www.facebook.com/weThink.Platform/" target="_blank">
              <img src="@/assets/images/fb-icon.svg" alt="Facebook">
            </a>
          </li>
          <li class="social-list__item">
            <a href="https://www.instagram.com/wethink.eu/" target="_blank">
              <img src="@/assets/images/insta-icon.svg" alt="Instagram">
            </a>
          </li>
          <li class="social-list__item">
            <a href="https://www.linkedin.com/company/thinkplatform/" target="_blank">
              <img src="@/assets/images/in-icon.svg" alt="Linkedin">
            </a>
          </li>
          <li class="social-list__item">
            <a href="https://twitter.com/weTHINK_europe" target="_blank">
              <img src="@/assets/images/tw-icon.svg" alt="Twitter">
            </a>
          </li>
        </ul>

        </div>
      </div>
    </div>
    <div class="user-cabinet">
      <div class="container" style="padding-top: 1px;">
        <div class="members__area">
          <div class="locked">
            <div class="locked__head">
              <div class="locked__head-price">
                <h3 v-html="title"></h3>
              </div>
            </div>
            <div class="locked__body">
<!--
              <ul class="locked__list">
                <li class="locked__item">Lorem ipsum dolor sit amet, consectetuer</li>
                <li class="locked__item">Lorem ipsum dolor sit amet, consectetuer Lorem ipsum
                  dolor sit amet, consectetuer</li>
                <li class="locked__item">Lorem ipsum dolor sit amet, consectetuer</li>
              </ul>
-->
              <div v-if="role === 'request'">
                  <p>Click the button below in order to undo requet.</p>
                  <hr>
                  <a
                    href="#"
                    class="locked__btn"
                    @click.prevent="onShowModal"
                  >
                    Undo Request
                  </a>
              </div>

              <div v-if="role === 'guest'">
                  <p>Click the button below in order to join the Strategy Project. You will
                    receive an email when a member has accepted or denied your request.</p>
                  <a
                    href="#"
                    class="locked__btn"
                    @click.prevent="onSendRequest"
                  >
                    Request Membership
                  </a>
              </div>
            </div>
          </div>
          <div class="locked-message">
            <h3>Do you have any  questions?</h3>
            Contact: <a href="mailto:info@wethink.eu">info@wethink.eu</a>
          </div>
        </div>
      </div>
    </div>
    <Modal
      @close="hideModal(modalUndoRequest)"
      :id=modalUndoRequest
      title=""
      buttonTextOk="Undo"
      buttonVariantOk="danger"
      :showButtonCancel="true"
      @cancel="hideModal(modalUndoRequest)"
      @ok="onUndoRequest"
    >
      <p>Are you sure?</p>
    </Modal>
  </div>
</template>
<script>
import ProjectService from '@/services/ProjectService';
import GroupService from '@/services/GroupService';

import Modal from '@/components/Modal.vue';
import Alert from '@/components/Alert.vue';

import day from '@/mixins/day';
import modal from '@/mixins/modal';
import me from '@/mixins/me';
import alert from '@/mixins/alert';

export default {
  name: 'StrategyProjectLocked',
  components: { Modal, Alert },
  mixins: [day, modal, me, alert],
  props: {
    project: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    groupID: {
      type: Number || Object,
      default: null,
    },
    cost: {
      type: Number || String || Object,
      default: null,
    },
    type: {
      type: String || Object,
      default: null,
    },
    role: {
      type: String || Object,
      default: null,
    },
  },
  data() {
    return {
      modalUndoRequest: 'modalUndoRequest',
    };
  },
  computed: {
    title() {
      if (this.groupID && (this.group.role === 'request')) return 'Undo Group';
      if (this.groupID
        && (this.group.role === 'guest' || this.group.role === 'request')) return 'Request Group';
      if (this.groupID
        && (!(this.group.role === 'guest' || this.group.role === 'request'))
        && this.type === 'Open') return 'Request Membership';
      if (!this.groupID && this.type === 'Open') return 'Request Membership';

      if (this.project.type === 'Private' && this.project.role === 'guest') return 'Request Membership';
      if (this.project.type === 'Private' && this.project.role === 'request') return 'Undo Project';

      return '';
    },
  },
  created() {
    this.$eventBus.$on('showAlert', this.showAlert);
  },
  methods: {
    onShowModal() {
      this.showModal(this.modalUndoRequest);
    },
    onUndoRequest() {
      if (this.groupID) this.undoRequestGroup();
      else this.undoRequestProject();
    },
    async undoRequestGroup() {
      try {
        const res = await GroupService.actionUser(this.groupID, { action: 'undo' });

        if (res.data.status === 200) {
          const alertText = {
            text: res.data.message,
            variant: 'success',
          };

          this.$router.push({ name: 'strategyProjectsPage', params: { alertText } });
        }
      } catch (e) {
        console.log({ textArg: e, variantArg: 'danger' });
      }
    },
    async undoRequestProject() {
      try {
        const res = await ProjectService.actionUser(this.project.id, { action: 'undo', user_id: this.me.id });

        if (res.data.status === 200) {
          this.$eventBus.$emit('sendRequestProjectSuccess', res.data.message);
          this.$eventBus.$emit('updateUserRole', 'guest');
        }
      } catch (e) {
        console.log({ textArg: e, variantArg: 'danger' });
      }
    },
    onSendRequest() {
      if (this.groupID && this.group.role === 'guest') this.sendRequestGroup();
      else this.sendRequestProject();
    },
    async sendRequestGroup() {
      try {
        const res = await GroupService.actionUser(this.groupID, { action: 'request' });

        if (res.data.status === 200) {
          const alertText = {
            text: res.data.message,
            variant: 'success',
          };

          this.$router.push({ name: 'strategyProjectsPage', params: { alertText } });
        }
      } catch (e) {
        console.log({ textArg: e, variantArg: 'danger' });
      }
    },
    async sendRequestProject() {
      try {
        const res = await ProjectService.actionUser(this.project.id, { action: 'request' });

        if (res.data.status === 200) {
          this.$eventBus.$emit('sendRequestProjectSuccess', res.data.message);
          this.$eventBus.$emit('updateUserRole', 'request');
        }
      } catch (e) {
        console.log({ textArg: e, variantArg: 'danger' });
      }
    },
    showAlert(message, type = 'success') {
      this.showAndHideAlertSuccess(message, type);
    },
  },
  beforeDestroy() {
    this.$eventBus.$off('showAlert', this.showAlert);
  },
};
</script>
<style lang="scss" scoped>
.locked__head-price h3 {
    font-size: 30px;
}

.sproject-article::after {
    width: 0;
    height: 0;
    margin-top: 0;
}
</style>
