<template>
  <div>
    <div class="sproject-content">
      <div class="container">
        <div class="sproject-article">
          <div class="sproject-article__date">
            <span>{{dayOfMonth}}</span>{{dayOfWeek}}
          </div>
          <h3 class="sproject-article__title">
            {{project.name}}
          </h3>

        <ul class="sproject-article__category">
          <li><a style="cursor: default">{{project.category_id}}</a></li>
          <li><a style="cursor: default">{{project.type}}</a></li>
        </ul>

        <ul class="social-list">
          <!-- <li class="social-list__item">
            <a href="">
              <img class="icon-flag" src="@/assets/images/flag-icon.svg" alt="">
              </a>
          </li> -->
          <li class="social-list__item">
            <a href="https://www.facebook.com/weThink.Platform/" target="_blank">
              <img src="@/assets/images/fb-icon.svg" alt="Facebook">
            </a>
          </li>
          <li class="social-list__item">
            <a href="https://www.instagram.com/wethink.eu/" target="_blank">
              <img src="@/assets/images/insta-icon.svg" alt="Instagram">
            </a>
          </li>
          <li class="social-list__item">
            <a href="https://www.linkedin.com/company/thinkplatform/" target="_blank">
              <img src="@/assets/images/in-icon.svg" alt="Linkedin">
            </a>
          </li>
          <li class="social-list__item">
            <a href="https://twitter.com/weTHINK_europe" target="_blank">
              <img src="@/assets/images/tw-icon.svg" alt="Twitter">
            </a>
          </li>
        </ul>

        </div>
      </div>
    </div>
    <div class="user-cabinet">
      <div class="container" style="padding-top: 1px;">
        <div class="members__area">
          <div class="locked">
            <div class="locked__head">
              <div class="locked__head-price">
                <h3>Hidden Project</h3>
              </div>
            </div>
            <div class="locked__body">
              <div class="locked__list">
                <p>This project hidden for you. Please contact to administrator</p>
              </div>
              <hr>
            </div>
          </div>
          <div class="locked-message">
            <h3>Do you have any  questions?</h3>
            Contact: <a href="mailto:info@wethink.eu">info@wethink.eu</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import day from '@/mixins/day';
import me from '@/mixins/me';

export default {
  name: 'StrategyProjectLocked',
  components: {},
  mixins: [day, me],
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
  beforeDestroy() {},
};
</script>
