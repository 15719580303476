<template>
  <div v-if="relatedProjects.length" class="related">
    <div class="related__head" :style="{backgroundColor: group.style_color}">
      <div class="container">Related Strategy Projects</div>
    </div>
    <div class="related__body">
      <div class="container">
        <VueSlickCarousel
          class="project__slider responsive"
          :arrows="true"
          :slidesToShow="slidesToShow"
          :slidesToScroll="slidesToScroll"
        >
        <template #prevArrow>
          <button
            class="slider-btn slider-btn__left"
            :style="'background: url('
              + require('@/assets/images/slider-arrow.svg')
              + ') no-repeat 0 0;'"
          >
          </button>
        </template>
        <template #nextArrow>
          <button
            class="slider-btn slider-btn__right"
            :style="'background: url('
              + require('@/assets/images/slider-arrow-right.svg')
              + ') no-repeat 0 0;'"
          >
          </button>
        </template>
          <CardRelatedStrategyProjects
            v-for="project in relatedProjects"
            :key="project.id + project.name"
            :project="project"
          />
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

import ProjectService from '@/services/ProjectService';

import CardRelatedStrategyProjects from '@/components/RelatedStrategyProjects/CardRelatedStrategyProjects.vue';

import exception from '@/mixins/exception';

export default {
  name: 'RelatedStrategyProjects',
  components: { CardRelatedStrategyProjects, VueSlickCarousel },
  mixins: [exception],
  props: {
    projectID: {
      type: Number,
      required: true,
    },
    group: {
      type: Object,
    },
  },
  data() {
    return {
      relatedProjects: [],
      slidesToShow: 2,
      slidesToScroll: 2,
    };
  },
  computed: {},
  created() {
    this.getRelatedProjects();
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.slidesToShow = 1;
      this.slidesToScroll = 1;
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 768) {
        this.slidesToShow = 1;
        this.slidesToScroll = 1;
      } else {
        this.slidesToShow = 2;
        this.slidesToScroll = 2;
      }
    });
  },
  methods: {
    async getRelatedProjects() {
      let res = null;
      const data = {
        page: this.$route.query.page || 1,
        'per-page': 10,
      };

      try {
        res = await ProjectService.getRelatedProjects(this.projectID, data);
        this.relatedProjects = res.data;
      } catch (e) {
        this.relatedProjects = [];

        this.handleException(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .slick-arrow {
  width: 50px;
  height: 100px;

  &:before {
    content: '';
  }
}
.slider-btn__left{
  left: -60px;
}
.slider-btn__right {
  right: -60px;
}
@media (max-width: 576px) {
  .slider-btn__left{
    left: -30px;
  }
  .slider-btn__right {
    right: -40px;
  }
}
</style>
