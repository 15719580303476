<template>
  <div class="sproject-article">
    <div class="sproject-article__date" :style="{backgroundColor: project.style_color}">
      <span>{{dayOfMonth}}</span>{{dayOfWeek}}
    </div>
    <h3 class="sproject-article__title">
      {{project.name}}
    </h3>
    <ul class="sproject-article__category">
      <li>
        <a
          :style="labelStyle"
        >{{project.style_name}}</a>
      </li>
      <li><a :style="labelStyle">{{project.category_id}}</a></li>
      <li><a :style="labelStyle">{{project.type}}</a></li>
    </ul>
    <SummaryBlock
      v-if="project.summary || project.summary_file || project.video_html"
      :projectID="project.id"
      :text="project.summary"
      :file="project.summary_file"
      :video_html="project.video_html"
      :style_color="project.style_color"
    />
    <SummaryMediaBlock v-if="project.id"
      :projectID="project.id"
      :style_color="project.style_color"
    />
    <div v-html="project.text"></div>
  </div>
</template>
<script>
import SummaryBlock from '@/views/pages/StrategyProjectView/Components/SummeryBlock.vue';
import SummaryMediaBlock from '@/views/pages/StrategyProjectView/Components/SummeryMediaBlock.vue';

import day from '@/mixins/day';

export default {
  name: 'StrategyProjectViewArticle',
  components: {
    SummaryBlock,
    SummaryMediaBlock,
  },
  mixins: [day],
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    labelStyle() {
      return {
        borderColor: this.project.style_color,
        color: this.project.style_color,
        cursor: 'default',
      };
    },
  },
  created() {},
  methods: {},
};
</script>
