import ProjectService from '@/services/ProjectService';

import exception from '@/mixins/exception';

export default {
  mixins: [exception],
  data() {
    return {
      posts: [],
      postParams: {
        page: 1,
        'per-page': 10,
      },
      totalPages: 1,
      headers: {},
      isLoadPageFirstly: true,
      isPosts: false,
      addNextPosts: true,
    };
  },
  created() {
    window.addEventListener('scroll', async () => {
      const {
        // scrollTop,
        // scrollHeight,
        clientHeight,
      } = document.documentElement;
      const related = await document.getElementsByClassName('related');
      if (related[0].getBoundingClientRect().top - clientHeight <= 0
        && this.addNextPosts) {
        this.addNextPosts = false;
        await this.getPosts();
      }
    });

    this.getPosts().then((posts) => {
      this.isPosts = !!posts.length;
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', async () => {
      const {
        // scrollTop,
        // scrollHeight,
        clientHeight,
      } = document.documentElement;
      const related = document.getElementsByClassName('related');
      if (related[0].getBoundingClientRect().top - clientHeight <= 0
        && this.addNextPosts) {
        this.addNextPosts = false;
        await this.getPosts();
      }
    });
  },
  methods: {
    async getPosts() {
      if (this.postParams.page <= this.totalPages) {
        // if (!this.isLoadPageFirstly) {
        //   const postArea = document.getElementsByClassName('post__area');
        //   postArea[0].scrollIntoView({ block: 'end', behavior: 'smooth' });
        // }
        // this.isLoadPageFirstly = false;
        try {
          const res = await ProjectService.getComments(this.$route.params.id, this.postParams);
          this.posts.push(...res.data);
          this.totalPages = res.headers['x-pagination-page-count'];
          this.postParams.page = +res.headers['x-pagination-current-page'] + 1;
          this.addNextPosts = true;
        } catch (e) {
          console.log({ textArg: e, variantArg: 'danger' });
          // this.posts = [];
          this.handleException(e);
        }
      }

      return this.posts;
    },
    onDeletePost(postID) {
      this.posts = this.posts.filter((fd) => fd.id !== postID);
    },
    onChangePostParams(postParams) {
      this.posts = [];
      this.totalPages = 1;
      this.postParams = postParams;
    },
  },
};
