<template>
  <div v-if="medias.length">
    <div class="summary"  style="background-color: #9BA7B3">
      <div class="summary__title">
        {{selectedMedia.title}}
      </div>
      <div v-if="selectedMedia.filename" class="summary__body">
        <object  v-if="selectedMedia.filename.includes('pdf')" type="application/pdf"
            :data="selectedMedia.filename"
            :height="selectedMedia.filename.includes('pdf') ? '720' : '370'">
        </object>
        <img v-else :src="selectedMedia.filename">
      </div>
    </div>
    <div class="slider-items__wrapper" style="margin-bottom: 50px;">
        <VueSlickCarousel
          class="slider-items__list responsive"
          :arrows="true"
          :slidesToShow="slidesToShow"
          :slidesToScroll="1"
        >
        <div
          v-for="media in medias"
          :key="media.id+media.filename"
          class="slider-items__item"
          @click="selectedMedia = media"
          :class="{active: selectedMedia.id === media.id}"
        >
          <div class="slider-items__img">
            <img
              :src="media.filename.includes('.pdf')
                ? require('@/assets/images/pdf.png')
                : media.filename"
              alt=""
            >
          </div>
          <span href="">
            <h3 class="slider-items__title">{{media.title}}</h3>
          </span>
        </div>
        <template #prevArrow>
          <button
            class="slider-btn slider-btn__left"
            :style="'background: url('
              + require('@/assets/images/slider-arrow.svg')
              + ') no-repeat 0 0; left: -60px;'"
          >
          </button>
        </template>
        <template #nextArrow>
          <button
            class="slider-btn slider-btn__right"
            :style="'background: url('
              + require('@/assets/images/slider-arrow-right.svg')
              + ') no-repeat 0 0; right: -60px;'"
          >
          </button>
        </template>
      </VueSlickCarousel>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

import ProjectService from '@/services/ProjectService';

export default {
  name: 'SummaryBlock',
  components: { VueSlickCarousel },
  mixins: [],
  props: {
    projectID: {
      type: Number,
    },
    style_color: {
      type: String,
    },
  },
  data() {
    return {
      medias: [],
      slidesToShow: 3,
      selectedMedia: null,
    };
  },
  computed: {},
  created() {
    this.getMedias();
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.slidesToShow = 2;
    }
    if (window.innerWidth <= 375) {
      this.slidesToShow = 1;
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth > 768) {
        this.slidesToShow = 3;
      }
      if (window.innerWidth <= 768) {
        this.slidesToShow = 2;
      }
      if (window.innerWidth <= 375) {
        this.slidesToShow = 1;
      }
    });
  },
  methods: {
    async getMedias() {
      const res = await ProjectService.getMedia(this.projectID, { 'per-page': 50 });
      this.medias = res.data.sort((a, b) => +a.sort - +b.sort);
      this.selectedMedia = { ...this.medias[0] };
    },
  },
};
</script>
<style lang="scss">
object {
  width: 100%;
}

.summary {
  width: 100%;
  padding: 40px 40px 45px 40px;
}

div.slick-slide > div:first-child {
  padding: 0 5px;
}

.slider-items__wrapper .slick-slide {
  margin: 0;
}

.slider-items__wrapper .slick-list {
  margin: 0;
}

.slider-items__list .slider-btn__left {
  left: -50px !important;
}

.slider-items__item {
  cursor: pointer;
}
.slider-items__item.active {
  background-color: #FFF;
  border-color: #1d5d99;
  color: #000;
}

.slick-arrow {
  width: 50px;
  height: 100px;

  &:before {
    content: ' ';
  }
}
</style>
