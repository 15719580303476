export default {
  computed: {
    dayOfMonth() {
      if (!this.project.created_at) {
        return '01';
      }

      return this.project.created_at.split(' ')[0].slice(0, -1);
    },
    dayOfWeek() {
      if (!this.project.created_at) {
        return 'JUN';
      }

      return this.project.created_at.split(' ')[1].toUpperCase();
    },
  },
};
