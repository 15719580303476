export default {
  methods: {
    getContrastYIQ(hexcolor) {
      let startPos = 0;

      if (hexcolor.includes('#')) startPos = 1;

      const r = parseInt(hexcolor.substr(startPos + 0, 2), 16);
      const g = parseInt(hexcolor.substr(startPos + 2, 2), 16);
      const b = parseInt(hexcolor.substr(startPos + 4, 2), 16);
      const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

      return (yiq >= 128) ? '#fff' : '#000';
    },
  },
};
