<template>
  <div id="create_project" class="create-project">
    <div class="container">
      <div class="create-project__inner">
        <h2 class="create-project__title">Turn your ideas into reality</h2>
        <div class="create-project__item">
          <div class="form-block">
            <Alert :variant="variant" v-if="text">{{ text }}</Alert>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form class="form-block" @submit.prevent="handleSubmit(onProjectCreate)">
                <div class="form-block__body">
                  <ValidationProvider
                    tag="div"
                    rules="required"
                    name="name"
                    :class="['form-row']"
                    v-slot="{ errors }">
                    <input
                      v-model="form.name"
                      class="form-control"
                      type="text"
                      :placeholder="projectNamePlaceholder">
                    <span v-if="errors.length" class=require_field>{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div class="form-row">
                    <select class="form-control" v-model="form.category_id">
                      <option v-for="category in categories"
                        :key="category.id + category.name"
                        :value="category.id">{{category.name}}</option>
                    </select>
                  </div>
                  <div class="form-row">
                    <select class="select_categories form-control" v-model="form.type">
                      <option v-for="option in options"
                        :key="option.value"
                        :value="option.value">{{option.text}}</option>
                    </select>
                  </div>
                  <div class="form-row">
                    <select class="select_categories form-control" v-model="form.channels">
                      <option v-for="channel in channels"
                              :key="channel.id"
                              :value="channel.id">{{channel.name}}</option>
                    </select>
                  </div>
                  <ValidationProvider
                    tag="div"
                    rules="required|max:400"
                    name="Description"
                    :class="['form-row']"
                    v-slot="{ errors }">
                    <textarea
                      v-model="form.text_short"
                      class="form-control"
                      cols="30"
                      rows="10"
                      maxlength="401"
                      placeholder="Please enter a short description of your project">
                    </textarea>
                    <span v-if="errors.length" class=require_field>{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div class="btns-group--right">
                      <button class="btn btn-primary" type="submit">Submit</button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProjectService from '@/services/ProjectService';
import DictionaryService from '@/services/DictionaryService';
import GroupService from '@/services/GroupService';

import Alert from '@/components/Alert.vue';

import exception from '@/mixins/exception';
import alert from '@/mixins/alert';
import scroll from '@/mixins/scroll';
import content from '@/mixins/content';

export default {
  name: 'StrategyProjectCreate',
  components: { Alert },
  mixins: [exception, alert, scroll, content],
  props: {},
  data() {
    return {
      form: {
        name: '',
        group_id: null,
        type: '1',
        text_short: '',
      },
      options: [
        { text: 'Open', value: '1' },
        { text: 'Private', value: '2' },
      ],
      categories: [],
      channels: [],
      group: null,
    };
  },
  computed: {
    projectNamePlaceholder() {
      if (this.group?.style_name === 'Showrooms') {
        return 'Showroom Name';
      }

      if (this.group?.style_name === 'Learning Platform') {
        return ' Training/Course Name';
      }

      return 'Project Name';
    },
  },
  async created() {
    this.categories = this.getCategories();
    this.channels = this.getChannels();
    if (this.$route.params.id) {
      this.group = await this.getGroup();
    }
  },
  methods: {
    async getGroup() {
      let res = null;

      try {
        res = await GroupService.group(this.$route.params.id);

        const group = res.data;
        return group;
      } catch (e) {
        this.handleException(e);
        return {};
      }
    },
    async onProjectCreate() {
      let res = null;

      try {
        res = await ProjectService.create(this.form);
        this.$router.push({ name: 'strategyProjectsEditor', params: { id: res.data.slug } });
      } catch (e) {
        if (e.response && e.response.status === 400) {
          this.scrollToTop('create_project');
          this.showAndHideAlertSuccess(e.response.data.message || 'Project with this name already exists!', 'danger');
        }
        console.log({ textArg: e.response.data.message, variantArg: 'danger' });
      }
    },
    async getCategories() {
      let res = null;

      try {
        res = await DictionaryService.categories();

        this.form.category_id = res.data[0].id;
        this.categories = res.data;
      } catch (e) {
        this.categories = [];
        this.handleException(e);
      }
    },
    async getChannels() {
      let res = null;

      try {
        res = await DictionaryService.getChannelsCategories();

        this.form.channels = res.data[0].id;
        this.channels = res.data;
      } catch (e) {
        this.channels = [];
        this.handleException(e);
      }
    },
  },
  mounted() {
    document.body.classList.remove('page-layout');
    document.body.style.background = 'transparent';
  },
  watch: {
    group() {
      this.form.group_id = this.group.id;
    },
  },
};
</script>
<style lang="scss" scoped>
.require_field {
  padding-left: 10px;
  font-size: 14px;
  color: #FF0000;
  font-weight: 500;
}
</style>
