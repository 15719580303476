<template>
  <div class="group-banner">
    <div class="container">
      <div
        class="group-banner__inner banner-gradient center-content"
        :style="{ backgroundColor: group.style_color }"
      >
        <div class="group-banner__head">
          <h2 class="group-banner__title">{{group.name}}</h2>
          <ul class="group-banner__statistic">
            <li><strong>{{group.cnt_user}}</strong> Members</li>
            <li><strong>{{group.cnt_project}}</strong> Strategy Projects</li>
          </ul>
          <router-link
            :to="{ name: 'profileGroupsView', params: { id: group.slug } }"
            class="btn btn-primary"
          >
            View Group
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'GroupBanner',
  components: {},
  mixins: [],
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  mounted() {
    document.body.classList.remove('page-layout');
  },
};
</script>
<style lang="scss" scoped>
</style>
