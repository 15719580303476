<template>
  <div>
    <div class="sproject-members" :style="{backgroundColor: styleColor}">
      <div class="container">
        <div class="sproject-members__box">
          <FiveAvatars
            :users="users"
            :countUsers="countUsers"
            :role="role"
          />
          <ul class="sproject-members__names">
            <li v-for="user in users" :key="user.avatar + user.email">
              <a>{{user.name}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FiveAvatars from '@/components/FiveAvatars.vue';

export default {
  name: 'StrategyProjectViewHeader',
  components: { FiveAvatars },
  props: {
    users: {
      type: Array,
      required: true,
    },
    countUsers: {
      type: Number,
      default: 0,
    },
    role: {
      type: String,
      default: 'guest',
    },
    styleColor: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.sproject-members__box {
  ::v-deep .container {
    margin: 0
  }
}
</style>
