<template>
  <div v-if="isShow">
    <GroupBanner
      v-if="group.id"
      :group="group"
    />
    <StrategyProjectsHeader
      v-if="project.users"
      :users="project.users"
      :countUsers="project.cnt_user"
      :styleColor="project.style_color"
      :role="project.role"
    />
    <StrategyProjectActive
      v-if="project.id && !checkProjectLocked()"
      :project="project"
      :group="group"
      :textAlert="textAlert"
    />
    <StrategyProjectLocked
      v-else-if="project.id && checkProjectLocked()"
      :project="project"
      :group="group"
      :groupID="group.id ? group.id : null"
      :cost="+cost"
      :type="type"
      :role="role"
    />
    <StrategyProjectHidden
      v-if="false && checkProjectLocked() && (group.role === 'hidden' || project.role === 'hidden')"
      :project="project"
    />
    <RelatedStrategyProjects
      v-if="project.id"
      :group="group"
      :projectID="project.id"
    />
  </div>
</template>
<script>
import ProjectService from '@/services/ProjectService';
import GroupService from '@/services/GroupService';

import exception from '@/mixins/exception';
import content from '@/mixins/content';
import websocket from '@/mixins/websocket';
import me from '@/mixins/me';

import GroupBanner from '@/views/pages/StrategyProjectView/Components/GroupBanner.vue';
import StrategyProjectsHeader from '@/views/pages/StrategyProjectView/Components/StrategyProjectsHeader.vue';
import StrategyProjectActive from '@/views/pages/StrategyProjectView/Components/StrategyProjectActive.vue';
import StrategyProjectLocked from '@/views/pages/StrategyProjectView/Components/StrategyProjectLocked.vue';
import StrategyProjectHidden from '@/views/pages/StrategyProjectView/Components/StrategyProjectHidden.vue';
import RelatedStrategyProjects from '@/components/RelatedStrategyProjects/RelatedStrategyProjects.vue';

export default {
  name: 'StrategyProjectView',
  components: {
    GroupBanner,
    StrategyProjectsHeader,
    StrategyProjectActive,
    StrategyProjectLocked,
    StrategyProjectHidden,
    RelatedStrategyProjects,
  },
  mixins: [exception, content, websocket, me],
  data() {
    return {
      isShow: false,
      projectID: this.$route.params.id,
      project: {},
      group: {},
      isProjectLocked: false,
      textAlert: '',
    };
  },
  computed: {
    cost() {
      return this.group.cost && (this.group.role === 'guest' || this.group.role === 'request')
        ? this.group.cost
        : this.project.cost;
    },
    type() {
      return this.group.type && (this.group.role === 'guest' || this.group.role === 'request')
        ? this.group.type
        : this.project.type;
    },
    role() {
      return this.group.role && (this.group.role === 'guest' || this.group.role === 'request')
        ? this.group.role
        : this.project.role;
    },
  },
  async beforeRouteUpdate(to, from, next) {
    this.isShow = false;
    this.projectID = to.params.id;
    this.project = {};
    this.group = {};
    this.isProjectLocked = false;
    await this.getProject();

    if (this.project.group_id) await this.getGroup();
    this.isProjectLocked = this.checkProjectLocked();
    this.isShow = true;

    next();
  },
  async created() {
    await this.getProject();

    if (this.project.group_id) await this.getGroup();
    this.isProjectLocked = this.checkProjectLocked();
    this.isShow = true;

    this.$eventBus.$on('projectLocked', this.handleGroup);
    this.$eventBus.$on('updateUserRole', this.setProjectRole);
    this.$eventBus.$on('updateUserGroupRole', this.updateUserGroupRole);
    this.$eventBus.$on('sendRequestProjectSuccess', this.setProjectUnlocked);
  },
  methods: {
    async getProject() {
      let res = null;

      try {
        res = await ProjectService.project(this.projectID);
        this.project = res.data;
      } catch (e) {
        this.project = {};

        if (e.response.status === 403) {
          this.project = e.response.data;
          this.isProjectLocked = true;
        }

        this.handleException(e);
      }
    },
    async getGroup() {
      let res = null;

      try {
        res = await GroupService.group(this.project.group_id);
        this.group = res.data;
      } catch (e) {
        this.group = {};
        this.handleException(e);
      }
    },
    handleGroup() {
      this.isProjectLocked = true;

      if (this.project.group_id) this.getGroup();
    },
    setProjectRole(role) {
      this.project.role = role;
    },
    checkProjectLocked() {
      // if (this.group.type === 'Private'
      //   && (this.group.role === 'guest'
      //      || this.group.role === 'request' || this.group.role === 'hidden')) {
      //   return true;
      // }

      if (this.group.type === 'Private'
        && (this.group.role === 'guest' || this.group.role === 'request' || this.group.role === 'hidden')) {
        return true;
      }

      if (this.project.type === 'Private'
        && (this.project.role === 'guest' || this.project.role === 'request' || this.project.role === 'hidden')) {
        return true;
      }

      return false;
    },
    setProjectUnlocked(text) {
      this.textAlert = text;
      this.isProjectLocked = false;
      document.querySelector('body').scrollIntoView({ behavior: 'smooth' });
    },
    updateUserGroupRole(role) {
      this.group.role = role;
    },
  },
  mounted() {
    document.body.classList.remove('page-layout');
    document.body.style.background = 'transparent';
  },
  beforeDestroy() {
    this.$eventBus.$off('projectLocked', this.setProjectLocked);
    this.$eventBus.$off('updateUserRole', this.setProjectRole);
    this.$eventBus.$off('sendRequestProjectSuccess', this.setProjectUnlocked);
    this.$eventBus.$off('updateUserGroupRole', this.updateUserGroupRole);
  },
};
</script>
